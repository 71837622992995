<script lang="ts">
    import { DefaultOptions } from '$src/types/search.types';
    import Button from '$src/components/Button.svelte';
    import WalkIcon from '$src/icons/WalkIcon.svelte';
    import MapPinIcon from '$src/icons/MapPinIcon.svelte';
    import SearchOptionList from './SearchOptionList/SearchOptionList.svelte';
    import SearchCalendarOption from './SearchCalendarOption/SearchCalendarOption.svelte';
    import PaxOption from './PaxOption/PaxOption.svelte';

    export let defaultDestinationOptions: DefaultOptions | undefined;
    export let defaultSportOptions: DefaultOptions | undefined;
    export let searchUrl: string;
</script>

<!-- calc width with padding because absolute positioning -->
<div
    class="relative mx-2xl h-[172px] desktop:m-auto desktop:h-[100px] desktop:max-w-layout-max"
>
    <div class="absolute top-[-140%] desktop:top-[-180%]">
        <div class="desktop:max-w-layout-max desktop:px-5xl">
            <h1
                class="text-left text-[26px] font-bold leading-[120%] text-white desktop:text-5xl"
            >
                Réservez votre <br /> prochain séjour sportif
            </h1>
        </div>
    </div>
    <div class="absolute top-[-90%] w-full desktop:top-[-50%]">
        <div class="desktop:max-w-layout-max desktop:px-5xl">
            <div class="border border-solid border-bord-quiet bg-white p-m">
                <form autocomplete="off" method="GET" action={searchUrl}>
                    <div class="flex flex-col gap-m desktop:flex-row">
                        <SearchOptionList
                            defaultOptions={defaultDestinationOptions}
                            inputName="destination[]"
                            inputLabel="Destination"
                            menuTitle="Sélectionner une destination"
                            icon={MapPinIcon}
                            placeholder="Ex: France"
                            placeholderMobile="Rechercher une destination"
                            defaultValueLabel="Toutes les destinations"
                        />
                        <SearchOptionList
                            defaultOptions={defaultSportOptions}
                            inputName="theme[]"
                            inputLabel="Sport"
                            menuTitle="Sélectionner un sport"
                            icon={WalkIcon}
                            placeholder="Ex: randonnée"
                            placeholderMobile="Rechercher un sport"
                            defaultValueLabel="Tous les sports"
                        />
                        <SearchCalendarOption />
                        <PaxOption />
                        <Button class="hover:bg-blue-brand-hover" type="submit"
                            >Rechercher</Button
                        >
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
