<script lang="ts">
    import { writable } from 'svelte/store';
    import { ComponentType } from 'svelte';

    import cn from '$src/utils/cn';
    import CloseCircle from '$src/icons/CloseCircle.svelte';
    import {
        FilteredValues,
        isDefaultOption,
        DefaultOptions,
    } from '$src/types/search.types';
    import Modal from '$src/components/Modal.svelte';
    import CrossIcon from '$src/icons/CrossIcon.svelte';
    import { ariaKeyDownA11yHandler } from '$src/utils/ariaKeyDownA11y';
    import { isParent } from '$src/utils/searchFilters';

    export let inputLabel: string;
    export let menuTitle: string | undefined;
    export let filteredOptions: FilteredValues | DefaultOptions | undefined;
    export let onInputChange: (event: Event) => void;
    export let resetInput: () => void;
    export let inputValue: string = '';
    export let icon: ComponentType;
    export let placeholder: string;
    export let placeholderMobile: string;

    let selectedLabel = '';
    let isModalOpen = writable(false);

    const openModal = () => {
        $isModalOpen = true;
    };

    const handleSelection = (option: { value: string; label: string }) => {
        inputValue = option.value;
        selectedLabel = option.label;
        $isModalOpen = false;
    };
</script>

<div>
    <div
        on:click={openModal}
        {...ariaKeyDownA11yHandler(openModal)}
        role="button"
        tabindex="0"
        class="flex justify-between gap-1 bg-container-quiet px-m py-xs"
    >
        <span
            class="flex items-center font-bold leading-6 text-content-neutral"
        >
            <span class="mr-xs flex items-center">
                <svelte:component this={icon} class="size-m" />
            </span>
            {inputLabel}
        </span>
        <div class="flex w-full items-center">
            <div class="w-full text-right leading-6 text-content-neutral">
                {#if selectedLabel}
                    {selectedLabel}
                {:else}
                    <span class="text-rock-200">{placeholder}</span>
                {/if}
            </div>
            <button
                type="button"
                class={cn('ml-xs', inputValue === '' && 'hidden')}
                on:click|preventDefault|stopPropagation={() => {
                    selectedLabel = '';
                    resetInput();
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
    </div>
</div>

<Modal bind:isOpen={isModalOpen}>
    <div slot="header" class="item-center flex justify-between">
        <div class="m-auto flex font-semibold text-content-neutral">
            {menuTitle}
        </div>
        <button
            on:click={() => ($isModalOpen = false)}
            class="focus-visible:outline-none"
        >
            <CrossIcon class="size-xl fill-content-neutral" />
        </button>
    </div>

    <!-- 76px offset for Modal Header-->
    <div class="ml-2xl flex h-full flex-col overflow-scroll pb-[76px]">
        <!-- Input -->
        <div class="my-m mr-2xl flex">
            <input
                type="text"
                on:input={onInputChange}
                bind:value={selectedLabel}
                placeholder={placeholderMobile}
            />
            <button
                type="button"
                class={cn(
                    'bg-container-quiet pr-m',
                    selectedLabel === '' && 'hidden',
                )}
                on:click|preventDefault|stopPropagation={() => {
                    selectedLabel = '';
                    resetInput();
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
        {#if filteredOptions}
            {#each filteredOptions as defaultOption}
                <button
                    type="button"
                    on:click={() => handleSelection(defaultOption)}
                    class={cn(
                        'mr-2xl cursor-pointer p-m text-left leading-6 text-content-neutral',
                        // if search not active (i.e. children[]) display parent in bold
                        isParent(defaultOption) && 'font-bold',
                        selectedLabel === defaultOption.label &&
                            'bg-commercial-neutral',
                    )}
                >
                    {defaultOption.label}
                </button>
                {#if isDefaultOption(defaultOption) && defaultOption.children}
                    {#each defaultOption.children as item}
                        <button
                            type="button"
                            on:click={() => handleSelection(item)}
                            class={cn(
                                'ml-m mr-2xl cursor-pointer p-m text-left text-content-neutral',
                                selectedLabel === item.label &&
                                    'bg-commercial-neutral',
                            )}
                        >
                            {item.label}
                        </button>
                    {/each}
                {/if}
            {/each}
        {/if}
    </div>
</Modal>

<style lang="postcss">
    input {
        appearance: unset;
        display: inline-block;
        margin: unset;
        padding: unset;
        max-width: unset;
        min-height: unset;
        outline: unset;
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border: unset;
        border-radius: unset;
        transition: unset;
        @apply block w-full bg-container-quiet px-m py-xs leading-6 placeholder:text-rock-200;
    }
</style>
