<script>
    import { onMount } from 'svelte';
    import dayjs from 'dayjs';
    import { isModalOpen, modalExtraData } from '$src/store/modal';
    import { formatTourDate, formatTourDate2 } from '$src/utils/date';
    import ChevronRightIcon from '$src/icons/ChevronRightIcon.svelte';
    import cn from '$src/utils/cn';

    export let tourDatesInfo, visibleDates, dict;

    let isShowingMore = false;

    // for analytics purposes
    onMount(() => {
        if (visibleDates.length === 0) {
            window.productDetail.push(['dateavailable', 0]);
        }
        if (visibleDates.length > 0) {
            window.productDetail.push(['dateavailable', visibleDates.length]); // string - Sport
        }
    });

    const handleSelectedDate = (date) => {
        $isModalOpen = true;
        $modalExtraData = {
            selectedDate: dayjs(date.date1.date).format('YYYY-MM-DD'),
            priceWithCustomerCarbonContribution: date.hasRebate
                ? date.rebatedPriceWithCustomerCarbonContribution
                : date.priceWithCustomerCarbonContribution,
            remainingStock: date?.remaining_stock ?? '',
            statusName: date?.statusName ?? '',
        };
    };
</script>

<div>
    {#if visibleDates.length > 0}
        <h2 class="mb-xs text-[26px] font-semibold text-content-neutral">
            {dict.dates.title}
        </h2>
        <div class="bg-white p-s">
            {#each visibleDates as date, index}
                <button
                    class={cn(
                        'flex w-full border-b border-solid border-border-quiet py-xs text-m',
                        !isShowingMore && index > 4 && 'hidden',
                        date.isFull && 'opacity-[0.38]',
                    )}
                    disabled={date.isFull}
                    on:click={() => !date.isFull && handleSelectedDate(date)}
                >
                    <span class="flex w-full flex-col gap-1 text-left">
                        <span class={cn(date.isFull && 'opacity-[0.38]')}>
                            du <span class="font-semibold">
                                {formatTourDate(date.date1)}
                            </span>
                            au
                            <span class="font-semibold">
                                {formatTourDate2(date.date2)}
                            </span>
                        </span>
                        {#if date.isFull}
                            <span class="font-semibold text-content-brand">
                                Complet
                            </span>
                        {:else}
                            <span class="text-content-brand">
                                <span class="font-semibold">
                                    {date.statusName}
                                </span>
                                {#if date.remaining_stock > 0}
                                    - {date.remaining_stock} places dispo
                                {/if}
                            </span>
                        {/if}
                        <span class="flex items-center gap-xs">
                            {#if date.hasRebate}
                                <span class="line-through">
                                    {date.priceWithCustomerCarbonContribution.toLocaleString()}€
                                </span>
                                <span
                                    class="w-fit bg-container-commercial-catchy p-2xs text-xs"
                                >
                                    {Math.round(date.rebatePercentage)}%
                                </span>

                                <span
                                    class={cn(
                                        'pl-xs font-semibold',
                                        date.isFull && 'opacity-[0.38]',
                                    )}
                                >
                                    {date.rebatedPriceWithCustomerCarbonContribution.toLocaleString()}€
                                </span>
                            {:else}
                                <span
                                    class={cn(
                                        'font-semibold',
                                        date.isFull && 'opacity-[0.38]',
                                    )}
                                >
                                    {date.priceWithCustomerCarbonContribution.toLocaleString()}€
                                </span>
                            {/if}
                        </span>
                    </span>
                    <span class="m-auto h-full p-m">
                        <ChevronRightIcon
                            class={cn(
                                'size-xl fill-content-neutral',
                                date.isFull &&
                                    'fill-content-quiet opacity-[0.38]',
                            )}
                        />
                    </span>
                </button>
            {/each}
            {#if !isShowingMore}
                <div class="my-m flex items-center justify-center">
                    <button
                        class="rounded-[64px] border-2 border-solid border-blue-brand px-m py-xs text-content-brand"
                        on:click={() => (isShowingMore = !isShowingMore)}
                    >
                        {dict.dates.showmore}
                    </button>
                </div>
            {/if}
        </div>
    {/if}
    {#if tourDatesInfo}
        <div class="info">
            <h3 class="heading">{dict.dates.dates_info}</h3>
            <div class="content is-rt">
                <!--  eslint-disable-next-line svelte/no-at-html-tags -->
                {@html tourDatesInfo}
            </div>
        </div>
    {/if}
</div>
