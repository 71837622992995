<script>
    import dayjs from 'dayjs';
    import { createDatePicker } from '@melt-ui/svelte';
    import { parseDate } from '@internationalized/date';

    import {
        findFirstAvailableMonth,
        formatTourDate,
        formatTourDate2,
    } from '$src/utils/date';
    import cn from '$src/utils/cn';
    import { TOUR_TYPE_FIT, TOUR_TYPE_GIR } from '$src/constants/tour';
    import ChevronRightIcon from '$src/icons/ChevronRightIcon.svelte';
    import ChevronLeftIcon from '$src/icons/ChevronLeftIcon.svelte';
    import Button from '$src/components/Button.svelte';

    export let dates;
    export let tourType;
    export let handleNextStep;
    export let closedSales;
    export let isBookable;

    const {
        elements: { calendar, cell, grid, heading, nextButton, prevButton },
        states: { value, months, headingValue, weekdays },
        helpers: { isDateDisabled, isDateUnavailable, setMonth, setYear },
    } = createDatePicker({
        locale: 'fr',
        name: 'selectedDate',
        numberOfMonths: 2,
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        isDateDisabled: (date) => {
            let isDisabled = false;
            for (const closedDate of closedSales) {
                const d = dayjs(date.toString());
                // '[]' is inclusive start+end day dates
                if (
                    d.isBetween(
                        closedDate.startDate,
                        closedDate.endDate,
                        'day',
                        '[]',
                    )
                )
                    isDisabled = true;
            }

            return isDisabled;
        },
    });

    $: {
        // find the closest date available, if not in current month then set
        // to the closest date's month
        // dayjs starts at 0, melt-ui at 1
        // + one more offset to display the 1st available month on the left
        const firstAvailableDate = findFirstAvailableMonth(closedSales);
        setYear(firstAvailableDate.year());
        setMonth(firstAvailableDate.month() + 1);
    }

    const handleGirDateSelection = (selectedItem) => {
        // TODO .Ts this file
        if (selectedItem?.date1?.date != null)
            handleNextStep(
                dayjs(selectedItem.date1.date).format('YYYY-MM-DD'),
                {
                    priceWithCustomerCarbonContribution: selectedItem.hasRebate
                        ? selectedItem.rebatedPriceWithCustomerCarbonContribution
                        : selectedItem.priceWithCustomerCarbonContribution,
                    remainingStock: selectedItem?.remaining_stock ?? '',
                    statusName: selectedItem?.statusName ?? '',
                },
            );
    };

    const handleFitDateSelection = () => {
        // format ebooking vs freequote
        // TODO harmonize date format in backend?
        handleNextStep(
            dayjs($value).format(isBookable ? 'DD/MM/YYYY' : 'YYYY-MM-DD'),
            null,
        );
    };
</script>

{#if tourType === TOUR_TYPE_GIR}
    <div class="pb-2xl pt-2xl">
        {#each dates as date}
            <button
                type="button"
                class="flex w-full border-b border-solid border-border-quiet py-xs text-sm"
                on:click={() => handleGirDateSelection(date)}
            >
                <span class="ml-xl flex w-full flex-col gap-1 text-left">
                    <span>
                        du <span class="font-semibold"
                            >{formatTourDate(date.date1)}</span
                        >
                        au
                        <span class="font-semibold"
                            >{formatTourDate2(date.date2)}</span
                        >
                    </span>
                    {#if date.isFull}
                        <span class="text-content-quiet"> Complet </span>
                    {:else}
                        <span class="text-content-brand">
                        <span class="font-semibold">{date.statusName}</span>
                        {#if date.remaining_stock > 0}
                            - {date.remaining_stock} places dispo
                        {/if}
                        </span>
                    {/if}
                    <span class="flex items-center gap-xs">
                        {#if date.hasRebate}
                            <span class="line-through">
                                {date.priceWithCustomerCarbonContribution}€
                            </span>
                            <span
                                class="w-fit bg-container-commercial-catchy p-2xs text-xs"
                            >
                                {Math.round(date.rebatePercentage)}%
                            </span>

                            <span class="pl-xs font-semibold">
                                {date.rebatedPriceWithCustomerCarbonContribution}€
                            </span>
                        {:else}
                            <span class="font-semibold">
                                {date.priceWithCustomerCarbonContribution}€
                            </span>
                        {/if}
                    </span>
                </span>
                <span class="m-auto h-full p-m">
                    <ChevronRightIcon class={'size-xl fill-content-neutral'} />
                </span>
            </button>
        {/each}
    </div>
{:else if tourType === TOUR_TYPE_FIT}
    <div
        class="relative flex h-full w-full items-center justify-center pb-[52px]"
    >
        <div {...$calendar} use:calendar>
            <div class="flex items-center justify-between pb-2">
                <button type="button" {...$prevButton} use:prevButton>
                    <ChevronLeftIcon class="size-xl" />
                </button>
                <div
                    {...$heading}
                    use:heading
                    class="text-sm capitalize text-content-neutral"
                >
                    {$headingValue.split('-')[0]}
                </div>
                <button type="button" {...$nextButton} use:nextButton>
                    <ChevronRightIcon class="size-xl" />
                </button>
            </div>
            {#each $months as month, index}
                <!-- Second month heading -->
                {#if index >= 1}
                    <div
                        {...$heading}
                        use:heading
                        class="mt-m w-full pb-xs text-center text-sm capitalize text-content-neutral"
                    >
                        {$headingValue.split('-')[1]}
                    </div>
                {/if}
                <table {...$grid} use:grid>
                    <thead aria-hidden="true">
                        <tr class="">
                            {#each $weekdays as day}
                                <th
                                    class="text-center text-xs font-semibold leading-4 text-content-quiet"
                                >
                                    {day}
                                </th>
                            {/each}
                        </tr>
                    </thead>
                    <tbody>
                        {#each month.weeks as days}
                            <tr>
                                {#each days as date}
                                    <td
                                        class="text-center leading-6"
                                        role="gridcell"
                                        aria-disabled={$isDateDisabled(date) ||
                                            $isDateUnavailable(date)}
                                    >
                                        <div
                                            {...$cell(date, month.value)}
                                            use:cell
                                            class="flex items-center justify-center"
                                        >
                                            <div>
                                                {date.day}
                                            </div>
                                        </div>
                                    </td>
                                {/each}
                            </tr>
                        {/each}
                    </tbody>
                </table>
            {/each}
        </div>

        <!-- Footer -->
        <div
            class="fixed bottom-0 left-0 flex w-full justify-center border-t border-solid border-border-quiet bg-white p-m pb-xl"
        >
            <Button
                intent={$value ? 'primary' : 'disabled'}
                class="w-full"
                disabled={$value == null}
                on:click={handleFitDateSelection}
            >
                Continuer
            </Button>
        </div>
    </div>
{/if}

<style lang="postcss">
    [data-melt-calendar] {
        @apply h-full w-full px-2xl pt-m;
    }

    [data-melt-calendar-grid] {
        @apply w-full;
    }

    table {
        border-spacing: 0 4px;
        border-collapse: separate;
    }

    [data-melt-calendar-cell] {
        @apply size-[36px] border-4 border-solid border-container-quiet bg-container-quiet p-1;
    }

    [data-melt-calendar-cell][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-unavailable] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }

    [data-melt-calendar-cell][data-selected] {
        @apply border-blue-brand bg-blue-brand text-white;
    }

    [data-melt-calendar-cell][data-selected][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-outside-visible-months] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }
</style>
