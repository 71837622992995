<script lang="ts">
    import dayjs from 'dayjs';
    import { createDatePicker } from '@melt-ui/svelte';
    import { parseDate } from '@internationalized/date';
    import ChevronLeftIcon from '$src/icons/ChevronLeftIcon.svelte';
    import ChevronRightIcon from '$src/icons/ChevronRightIcon.svelte';
    import { fade } from 'svelte/transition';
    import cn from '$src/utils/cn';
    import { findFirstAvailableMonth } from '$src/utils/date';

    export let closedSales;
    export let disabled;
    let closedSalesFormatted;
    $: {
        closedSalesFormatted = JSON.parse(closedSales);
        closedSalesFormatted = closedSalesFormatted.map((closedSale) => {
            return {
                startDate: dayjs(closedSale.startDate),
                endDate: dayjs(closedSale.endDate),
            };
        });
    }
    let calendarTriggerBtn;

    const {
        elements: {
            trigger,
            calendar,
            cell,
            grid,
            heading,
            nextButton,
            prevButton,
            content,
            segment,
            hiddenInput,
        },
        states: {
            value,
            months,
            headingValue,
            segmentContents,
            open,
            weekdays,
        },
        helpers: { isDateDisabled, isDateUnavailable, setMonth, setYear },
    } = createDatePicker({
        locale: 'fr',
        name: 'startDate',
        numberOfMonths: 2,
        forceVisible: true,
        positioning: {
            placement: 'bottom-end',
        },
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        isDateDisabled: (date) => {
            let isDisabled = false;
            for (const closedDate of closedSalesFormatted) {
                const d = dayjs(date.toString());
                // '[]' is inclusive start+end day dates
                if (
                    d.isBetween(
                        closedDate.startDate,
                        closedDate.endDate,
                        'day',
                        '[]',
                    )
                )
                    isDisabled = true;
            }

            return isDisabled;
        },
        onValueChange: (dateValue) => {
            // close calendar on choosing date value
            calendarTriggerBtn?.click();

            return dateValue.next;
        },
    });

    $: {
        if ($open) {
            // find the closest date available, if not in current month then set
            // to the closest date's month
            // dayjs starts at 0, melt-ui at 1
            // + one more offset to display the 1st available month on the left
            const firstAvailableDate =
                findFirstAvailableMonth(closedSalesFormatted);
            setYear(firstAvailableDate.year());
            setMonth(firstAvailableDate.month() + 1);
        }
    }
</script>

<div class="mb-m">
    <button
        bind:this={calendarTriggerBtn}
        id="calendar-fit-trigger-btn"
        type="button"
        {...$trigger}
        use:trigger
        class={cn(
            'flex h-4xl w-full items-center border border-solid border-border-neutral bg-white pl-m pr-2xl',
            disabled && 'hidden',
        )}
    >
        {#if $value == null}
            <div class="text-sm text-content-neutral">Choisir une date</div>
        {:else}
            {#each $segmentContents as seg}
                <div
                    {...$segment(seg.part)}
                    use:segment
                    class="text-sm text-content-neutral"
                >
                    {seg.value}
                </div>
            {/each}
        {/if}
    </button>
    <input
        {...$hiddenInput}
        use:hiddenInput
        hidden={false}
        id="next-dates"
        value={$value != null ? dayjs($value).format('DD/MM/YYYY') : ''}
        on:input={() => {
            document.getElementById('calendar-fit-trigger-btn').click();
        }}
    />

    {#if $open}
        <div
            class=""
            transition:fade={{ duration: 100 }}
            {...$content}
            use:content
        >
            <div {...$calendar} use:calendar>
                <div class="flex items-center justify-between pb-2">
                    <button type="button" {...$prevButton} use:prevButton>
                        <ChevronLeftIcon class="size-xl" />
                    </button>
                    <div
                        {...$heading}
                        use:heading
                        class="flex w-full justify-between gap-x-8 text-sm capitalize text-content-neutral"
                    >
                        <div class="flex-1 text-center">
                            {$headingValue.split('-')[0]}
                        </div>
                        <div class="flex-1 text-center">
                            {$headingValue.split('-')[1]}
                        </div>
                    </div>
                    <button type="button" {...$nextButton} use:nextButton>
                        <ChevronRightIcon class="size-xl" />
                    </button>
                </div>
                <div class="flex gap-x-8">
                    {#each $months as month}
                        <table {...$grid} use:grid>
                            <thead aria-hidden="true">
                                <tr class="border-8 border-solid border-white">
                                    {#each $weekdays as day}
                                        <th
                                            class="text-center text-xs font-bold leading-4 text-content-quiet"
                                        >
                                            {day}
                                        </th>
                                    {/each}
                                </tr>
                            </thead>
                            <tbody>
                                {#each month.weeks as days}
                                    <tr>
                                        {#each days as date}
                                            <td
                                                class="text-center text-xs leading-4"
                                                role="gridcell"
                                                aria-disabled={$isDateDisabled(
                                                    date,
                                                ) || $isDateUnavailable(date)}
                                            >
                                                <div
                                                    {...$cell(
                                                        date,
                                                        month.value,
                                                    )}
                                                    use:cell
                                                    class="flex items-center justify-center"
                                                >
                                                    <div>
                                                        {date.day}
                                                    </div>
                                                </div>
                                            </td>
                                        {/each}
                                    </tr>
                                {/each}
                            </tbody>
                        </table>
                    {/each}
                </div>
            </div>
        </div>
    {/if}
</div>

<style lang="postcss">
    [data-melt-popover-content] {
        @apply z-50 border border-solid border-border-quiet bg-white;
    }
    [data-melt-calendar] {
        @apply h-full w-full px-2xl pt-m;
    }

    [data-melt-calendar-grid] {
        @apply w-full;
    }

    table {
        border-spacing: 4px 4px;
        border-collapse: separate;
    }

    [data-melt-calendar-cell] {
        @apply size-[36px] bg-container-quiet p-1;

        &:hover {
            @apply bg-container-commercial-neutral;
        }
    }

    [data-melt-calendar-cell][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-unavailable] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }

    [data-melt-calendar-cell][data-selected] {
        @apply border-blue-brand bg-blue-brand text-white;
    }

    [data-melt-calendar-cell][data-selected][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-outside-visible-months] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }
</style>
