<script lang="ts">
    import type { HTMLButtonAttributes } from 'svelte/elements';
    import type { VariantProps } from 'class-variance-authority';
    import { cva } from 'class-variance-authority';

    import cn from '$src/utils/cn';

    const button = cva('text-white', {
        variants: {
            intent: {
                primary: 'bg-blue-brand uppercase',
                disabled: 'opacity-30',
                link: 'bg-none text-content-neutral underline',
            },
            shape: {
                squared: 'rounded-none',
                rounded: 'rounded-full',
            },
            size: {
                none: 'p-0',
                small: 'px-l py-xs text-xs',
                medium: 'px-xl py-s text-sm',
                large: 'px-3xl py-l text-sm',
            },
        },
        compoundVariants: [],
    });

    /**
     * For Svelte components, we recommend setting your defaultVariants within
     * Svelte props (which are `undefined` by default)
     */
    interface $$Props
        extends HTMLButtonAttributes,
            VariantProps<typeof button> {}

    /**
     * For Svelte components, we recommend setting your defaultVariants within
     * Svelte props (which are `undefined` by default)
     */
    export let intent: $$Props['intent'] = 'primary';
    export let size: $$Props['size'] = 'medium';
    export let shape: $$Props['shape'] = 'squared';
    export let disabled: $$Props['disabled'] = false;
</script>

<button
    {...$$props}
    on:click
    {disabled}
    class={cn(button({ intent, size, shape }), $$props.class)}
>
    <slot />
</button>
