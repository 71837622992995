import dayjs from 'dayjs';
var isBetween = require('dayjs/plugin/isBetween');
require('dayjs/locale/fr');
dayjs.locale('fr');
dayjs.extend(isBetween);

// tour date format is DD/MM/YYYY
export const formatTourDate = (date) => {
    return dayjs(date.date).format('DD MMM');
};

export const formatTourDate2 = (date) => {
    return dayjs(date.date).format('DD MMM YYYY');
};

/**
 * Return the first available month index (dayjs index starts at 0)
 * @param closedDates
 * @returns dayjs Date
 */
export const findFirstAvailableMonth = (closedDates) => {
    const currentDate = dayjs();

    const sortedDisabledDates = closedDates.sort((a, b) =>
        a.endDate.isAfter(b.endDate) ? 1 : -1,
    );

    let firstAvailableDate = currentDate;

    for (let i = 0; i < sortedDisabledDates.length; i++) {
        const endDate = sortedDisabledDates[i].endDate;
        const nextStartDate = sortedDisabledDates[i + 1]
            ? sortedDisabledDates[i + 1].startDate
            : null;

        if (
            (nextStartDate && endDate < nextStartDate) ||
            (!nextStartDate && endDate > currentDate)
        ) {
            firstAvailableDate = endDate.add(1, 'day');
            break;
        }
    }

    if (!firstAvailableDate && sortedDisabledDates.length > 0) {
        const lastEndDate =
            sortedDisabledDates[sortedDisabledDates.length - 1].endDate;
        firstAvailableDate = dayjs()
            .set(lastEndDate.year(), 'year')
            .set(lastEndDate.month() + 1, 'month')
            .set(1, 'date');
    }

    return firstAvailableDate;
};
