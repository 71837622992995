<script lang="ts">
    import { createPopover } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';

    import cn from '$src/utils/cn';
    import { PaxValues } from '$src/views/HomePage/PaxOption/types';
    import CloseCircle from '$src/icons/CloseCircle.svelte';
    import Button from '$src/components/Button.svelte';
    import FamilyIcon from '$src/icons/FamilyIcon.svelte';
    import PaxForm from '$src/components/Filters/PaxForm.svelte';

    export let adultsValue: string;
    export let childrenValue: string;
    export let childrenAges: string[];
    export let handleSubmitValues: (values: PaxValues) => void;

    let popoverTriggerBtn: HTMLDivElement;

    const {
        elements: { trigger, content, arrow },
        states: { open },
    } = createPopover({
        forceVisible: true,
    });

    $: totalPax = Number(adultsValue) + Number(childrenValue);
</script>

<div
    bind:this={popoverTriggerBtn}
    {...$trigger}
    use:trigger
    class="flex w-auto flex-col bg-container-quiet px-m py-xs hover:bg-secondary-regular-container-hover"
>
    <div class="flex w-full flex-col gap-0.5">
        <div class="flex font-bold leading-6 text-content-neutral">
            <span class="mr-xs flex items-center">
                <FamilyIcon class="size-m" />
            </span>
            Voyageurs
        </div>
        <div class="flex w-full items-center">
            <div class="w-full leading-6 text-content-neutral">
                {#if totalPax > 0}
                    {totalPax} voyageur{totalPax > 1 ? 's' : ''}
                {:else}
                    &nbsp;<span class="text-rock-200">Ex: 1 voyageur</span>
                {/if}
            </div>
            <button
                type="button"
                class={cn('ml-xs', totalPax === 0 && 'hidden')}
                on:click|preventDefault|stopPropagation={() => {
                    handleSubmitValues({
                        adultsValue: '0',
                        childrenAges: [],
                        childrenValue: '0',
                    });
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
    </div>
</div>

{#if $open}
    <div
        {...$content}
        use:content
        transition:fade={{ duration: 100 }}
        class="w-[430px] border border-solid border-bord-quiet bg-white p-2xl"
    >
        <div {...$arrow} use:arrow />

        <PaxForm
            {childrenAges}
            {childrenValue}
            {adultsValue}
            handleSubmit={(values) => {
                handleSubmitValues(values);
                // click to close modal
                popoverTriggerBtn?.click();
            }}
        >
            <!--  Footer validate button -->
            <div
                slot="footer"
                class="flex w-full justify-center border-t border-solid border-commercial-neutral bg-white py-m"
            >
                <Button class="w-full hover:bg-blue-brand-hover" type="submit"
                    >Valider</Button
                >
            </div>
        </PaxForm>
    </div>
{/if}
