<script lang="ts">
    import cn from '$src/utils/cn';
    export let flexibility: number;
</script>

<div class="m-2xl flex flex-wrap justify-center gap-2">
    {#each [0, 1, 2, 3, 5, 7] as index}
        <button
            type="button"
            class={cn(
                'flexibility-btn',
                flexibility === index && 'flexibility-btn-active',
            )}
            on:click={() => (flexibility = index)}
        >
            {#if index === 0}
                Dates exactes
            {:else}
                ± {index} jour{index > 1 ? 's' : ''}
            {/if}
        </button>
    {/each}
</div>

<style lang="postcss">
    .flexibility-btn {
        @apply inline-block whitespace-nowrap rounded-2xl border border-solid border-content-inactive
        px-xs py-2xs text-xs text-content-inactive hover:border-border-brand hover:text-content-brand;
    }

    .flexibility-btn-active {
        @apply border-border-brand font-bold text-content-brand transition-all;
    }
</style>
