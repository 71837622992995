<script lang="ts">
    import Button from '$src/components/Button.svelte';
    import { DefaultOptionList } from '$src/types/search.types';
    import MapPinIcon from '$src/icons/MapPinIcon.svelte';
    import WalkIcon from '$src/icons/WalkIcon.svelte';
    import { PaxValues } from '$src/views/HomePage/PaxOption/types';
    import PaxDesktop from '$src/components/Filters/desktop/PaxDesktop.svelte';
    import CalendarDesktop from '$src/components/Filters/desktop/CalendarDesktop.svelte';
    import { flattenSportsFilter } from '$src/utils/searchFilters';
    import { FilterFormValues } from '../types';
    import OptionList from './Filters/OptionList.svelte';

    export let defaultOptions: DefaultOptionList;
    export let formValues: FilterFormValues;

    const handleSavePax = (values: PaxValues) => {
        formValues.children = values.childrenValue;
        formValues.adults = values.adultsValue;
    };
</script>

<div class="relative m-auto hidden h-[50px] max-w-layout-max desktop:block">
    <div class="absolute top-[-75px] w-full px-5xl">
        <div class="border border-solid border-bord-quiet bg-white p-m">
            <div class="flex flex-row gap-m">
                <div class="w-full">
                    <OptionList
                        options={defaultOptions.destinations}
                        bind:inputValue={formValues.destination}
                        inputLabel="Destination"
                        menuTitle="Sélectionner une destination"
                        icon={MapPinIcon}
                        placeholder="Ex: France"
                        defaultValueLabel="Toutes les destinations"
                    />
                </div>
                <div class="w-full">
                    <OptionList
                        options={flattenSportsFilter(defaultOptions.sports)}
                        bind:inputValue={formValues.theme}
                        inputLabel="Sport"
                        menuTitle="Sélectionner un sport"
                        icon={WalkIcon}
                        placeholder="Ex: randonnée"
                        defaultValueLabel="Tous les sports"
                    />
                </div>

                <div class="w-full">
                    <CalendarDesktop
                        bind:startDateValue={formValues.startDate}
                        bind:flexibility={formValues.flexibility}
                    />
                </div>

                <div class="w-full">
                    <PaxDesktop
                        childrenAges={formValues.childrenAges}
                        childrenValue={formValues.children}
                        adultsValue={formValues.adults}
                        handleSubmitValues={handleSavePax}
                    />
                </div>

                <Button
                    class="hover:bg-blue-brand-hover"
                    type="submit"
                    form="travel-search-engine-form">Rechercher</Button
                >
            </div>
        </div>
    </div>
</div>
