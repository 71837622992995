<script>
    import Modal from '$src/components/Modal.svelte';
    import Button from '$src/components/Button.svelte';
    import InfoSvg from '$src/icons/InfoIcon';
    import CrossIcon from '$src/icons/CrossIcon.svelte';
    import ChevronLeftIcon from '$src/icons/ChevronLeftIcon.svelte';
    import { modalExtraData, isModalOpen } from '$src/store/modal';
    import { filterTourDatesIsTravelAloneAuthorized } from '$src/utils/tour';
    import cn from '$src/utils/cn';
    import { TOUR_TYPE_FIT, TOUR_TYPE_GIR } from '$src/constants/tour';
    import SelectTourDateStepForm from './form/SelectTourDateStepForm.svelte';
    import SelectPaxStepForm from './form/SelectPaxStepForm/SelectPaxStepForm.svelte';

    export let isTravelAloneAuthorized;
    export let isClosedSales;
    export let code;
    export let maxAgeAllowed;
    export let minAgeAllowed;
    export let bookingType;
    export let tourType;
    export let minPrice;
    export let originalPrice;
    export let rebatePercentage;
    export let token;
    export let csrfToken;
    export let isBookable;
    export let bookableDates;
    export let allTourUrl;
    export let childrenAges;
    export let bookingFormAction;
    export let bookingRequestAction;
    // FIT only
    export let closedSales;

    const isGir = tourType === TOUR_TYPE_GIR;
    const isFit = tourType === TOUR_TYPE_FIT;
    const ageRules = {
        max: {
            isUnder18Allowed: maxAgeAllowed && maxAgeAllowed < 18,
        },
        min: {
            isUnder18Allowed: minAgeAllowed && minAgeAllowed < 18,
        },
    };
    const initialFormState = {
        selectedDate: null,
        adults: ageRules.max.isUnder18Allowed
            ? 0
            : isTravelAloneAuthorized
            ? 1
            : 2,
        children: 0,
        childrenAges: [],
    };

    // Hack to get the writable store as reactive variable
    // import get the var as readonly
    let isOpen = isModalOpen;
    let formValues = { ...initialFormState };
    let step = 1;
    let datesInfos = {};

    // skip to step 2 if user select date from PDP directly
    // also save additional infos (sent from VisibleDates)
    $: {
        if ($modalExtraData.selectedDate != null)
            handleNextStep($modalExtraData.selectedDate, {
                priceWithCustomerCarbonContribution:
                    $modalExtraData.priceWithCustomerCarbonContribution ?? null,
                remainingStock: $modalExtraData?.remainingStock ?? '',
                statusName: $modalExtraData?.statusName ?? '',
            });
    }

    const availableDates = filterTourDatesIsTravelAloneAuthorized(
        bookableDates,
        isTravelAloneAuthorized,
    );

    const handleNextStep = (date, infos) => {
        // reset
        formValues = { ...initialFormState };

        // then apply selected date
        step = 2;
        formValues.selectedDate = date;

        if (infos) {
            datesInfos = infos;
        }
    };

    const resetForm = () => {
        formValues.selectedDate = null;
        step = 1;
        $modalExtraData = {};
    };

    // Redirects to ebooking funnel or request funnel
    const handleSubmit = (event) => {
        const form = event.target;
        form.action = isBookable ? bookingFormAction : bookingRequestAction;
        form.method = isBookable ? 'POST' : 'GET';

        if (isBookable) {
            // eslint-disable-next-line no-undef
            window.triggerAddToCart(true);
        }

        form.submit();
    };
</script>

<div
    class="fixed bottom-0 left-0 z-20 w-full border-t border-solid border-border-quiet bg-container-commercial-neutral p-m text-content-neutral desktop:hidden"
>
    <!--  Indispo sejour /-->
    {#if isGir && (isClosedSales || bookableDates.length === 0)}
        <div class="mb-xs flex justify-center">
            <div class="mr-xs">
                <InfoSvg class="mt-2xs size-m fill-white" />
            </div>
            <p class="text-m">
                Ce séjour n’est pas disponible à la réservation pour le moment.
            </p>
        </div>
    {/if}

    <div class="flex flex-wrap justify-center gap-2xs text-center">
        <div class="flex items-center gap-2xs">
            <div class="text-sm font-semibold">À partir de</div>
            {#if rebatePercentage != null}
                <div
                    class={cn(
                        'text-[20px] font-semibold',
                        originalPrice && 'line-through',
                    )}
                >
                    {originalPrice}
                </div>
                <div
                    class="w-fit self-center bg-container-commercial-catchy p-2xs text-xs"
                >
                    <span>{rebatePercentage}</span>%
                </div>
            {/if}
        </div>
        <div
            class="text-[20px] font-semibold"
            data-aside-panel-actualprice-target
        >
            {#if originalPrice != null}
                {minPrice}
            {:else}
                {minPrice}
            {/if}
            <span class="text-sm font-normal">/ pers</span>
        </div>
    </div>

    <div class="mt-xs">
        {#if isGir && (isClosedSales || bookableDates.length === 0)}
            <Button itent="primary" class="w-full">
                <a href={allTourUrl}> Voir tous nos séjours </a>
            </Button>
        {:else}
            <div class="flex justify-center">
                <Button
                    intent="primary"
                    class="w-full"
                    on:click={() => ($isModalOpen = true)}
                >
                    {#if isBookable}
                        Réserver votre séjour
                    {:else}
                        Demander un devis
                    {/if}
                </Button>
            </div>
        {/if}
    </div>
</div>

<Modal bind:isOpen onClose={resetForm}>
    <!-- Header -->
    <div slot="header" class="item-center flex justify-between">
        {#if step === 1}
            <button
                on:click={() => {
                    $isModalOpen = false;
                    resetForm();
                }}
                class="focus-visible:outline-none"
            >
                <CrossIcon class="size-xl fill-content-neutral" />
            </button>
        {:else if step === 2}
            <button
                on:click={() => {
                    step = 1;
                    // reset extra data to avoid reactive loop
                    $modalExtraData = {};
                }}
            >
                <ChevronLeftIcon class="size-xl fill-content-neutral" />
            </button>
        {/if}
        <!-- margin to account for cross icon-->
        <div class="mr-2xl grow text-center">
            {#if step === 1}
                <div class="text-lg font-bold">Date de départ</div>
            {:else if step === 2}
                <div>
                    <span class="text-lg font-bold">À partir de </span>
                    <span class="text-2xl font-bold">
                        {#if isGir}
                            {`${datesInfos.priceWithCustomerCarbonContribution}€`}
                        {:else if isFit}
                            {minPrice}
                        {/if}
                    </span>
                    <span class="text-m">/adulte</span>
                </div>
            {/if}
        </div>
    </div>

    <div
        class="fixed h-[calc(100vh-76px)] w-full overflow-scroll"
        id="formContentContainer"
    >
        <!-- Content-->
        <form
            on:submit|preventDefault|stopPropagation={handleSubmit}
            class="h-full w-full"
        >
            <input
                class="hidden"
                name="startDate"
                value={formValues.selectedDate ? formValues.selectedDate : ''}
            />
            <input class="hidden" name="token" bind:value={token} />
            <input class="hidden" name="code" bind:value={code} />
            <input class="hidden" name="csrf_token" bind:value={csrfToken} />

            {#if step === 1}
                <SelectTourDateStepForm
                    {tourType}
                    dates={availableDates}
                    {handleNextStep}
                    {closedSales}
                    {isBookable}
                />
            {:else if step === 2}
                <SelectPaxStepForm
                    {tourType}
                    {bookingType}
                    {isTravelAloneAuthorized}
                    {minAgeAllowed}
                    {maxAgeAllowed}
                    bind:formValues
                    {childrenAges}
                    {ageRules}
                    {datesInfos}
                />
                <!-- footer -->
                <div class="fixed bottom-0 w-full p-m text-center">
                    <Button class="w-full" type="submit">Continuer</Button>
                </div>
            {/if}
        </form>
    </div>
</Modal>

<style lang="postcss">
    /* iOS Safari height hack
      detect safari ios only */
    @supports (-webkit-touch-callout: none) {
        /* use dvh
         https://stackoverflow.com/a/75648985  */
        #formContentContainer {
            height: calc(100dvh - 77px);
        }
    }
</style>
